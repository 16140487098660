import { createApp } from 'vue'
import router from './router/index'
import 'vuetify/dist/vuetify.min.css'
import './styles/tailwind.css'
import SvgLoader from './globalComponents/SvgLoader.vue'
import i18n from './libs/i18n/index'
import { App as VueApp } from 'vue'
import App from './App.vue'
import './style.scss'
import { registerLayouts } from './layouts/register'
import { createPinia } from 'pinia'
import './libs/veeValidate/vee-validate.ts'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { startTokenRefresh } from './services/TokenRefreshService'
import LoadingComponent from '@/globalComponents/LoadingComponent.vue'
import '@mdi/font/css/materialdesignicons.css'
import CounterComponent from '@/globalComponents/CounterComponent.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import LanguageSelector from '@/globalComponents/LanguageSelector.vue'
import vuetify from '@/libs/vuetify/vuetify.ts'
import DarkMode from '@/globalComponents/DarkMode.vue'
import { Field } from 'vee-validate';
// Define the type for the plugin
interface PluginType {
  install: (app: VueApp) => void
}

const app = createApp(App)

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDVAlKFaKKho3Wdrhk7W68BFaNeLiGI9lA',
    libraries: 'places'
  }
})

app.use(vuetify)
app.use(router)
registerLayouts(app)
app.use(i18n as PluginType)
app.use(Vue3Toastify, {
  autoClose: 5000,
  theme: 'dark',
  className: 'toastify-custom-dark-background'
} as ToastContainerOptions)
app.use(createPinia())
app.component('Field', Field);
app.component('SvgLoader', SvgLoader)
app.component('LoadingComponent', LoadingComponent)
app.component('CounterComponent', CounterComponent)
app.component('LanguageSelector', LanguageSelector)
app.component('DarkMode', DarkMode)
startTokenRefresh(router)

app.mount('#app')
window.addEventListener('vite:preloadError', () => {
  window.location.reload() // Refresh the page on preload error
})
