import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { toast } from 'vue3-toastify'
import Cookies from 'js-cookie'
// import { useRoute } from 'vue-router'

// Get base URL from environment variable
const baseURL: string = import.meta.env.VITE_BASE_URL as string

// Create Axios instance with base URL
const instance: AxiosInstance = axios.create({
  baseURL,
  maxRedirects: 0
})

// Function to retrieve the access token from cookies using cookie-js
function getAccessTokenFromCookie() {
  return Cookies.get('access_token') // Get access token from cookie
}
function getLanguageFromLocalStorage() {
  return localStorage.getItem('lang') || 'en' // Default to 'en' if no language is set
}

// Add an interceptor to add the Authorization header to each request
instance.interceptors.request.use(
  (config) => {
    const language = getLanguageFromLocalStorage()
    const accessToken = getAccessTokenFromCookie()

    if (config.params) {
      config.params.lang = language
    } else {
      config.params = { lang: language }
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Helper function for error handling
async function handleRequestError<T>(error: any, defaultMessage: string): Promise<T> {
  let errorMessage = defaultMessage
  // const route = useRoute()

  // Detect internet connectivity issues
  if (!navigator.onLine) {
    // User is offline
    toast.error('You are offline. Please check your internet connection.');
  } else if (!error.response) {
    // Other network-related issues (server unreachable, etc.)
    toast.error('Unstable connection. Please try again.');
  } else if (error.code === 'ECONNABORTED') {
    // Request timeout
    toast.error('Request timed out. Please try again later.');
  } else if (error.response && error.response.data) {
    // Server responded but with an error
    const responseData = error.response.data;

    if ('detail' in responseData) {
      errorMessage = responseData.detail
    } else if ('error' in responseData) {
      errorMessage = responseData.error
    } else if ('old_password' in responseData){
      errorMessage = responseData.old_password
    } else if ('new_password' in responseData){
      errorMessage = responseData.new_password
    }
  }

  toast.error(errorMessage)
  throw new Error(errorMessage)
}

const baseService = {
  // GET request
  async get<T>(url: string): Promise<T> {
    try {
      const response: AxiosResponse<T> = await instance.get(url)
      return response.data
    } catch (error: any) {
      return handleRequestError<T>(error, 'Error while fetching data')
    }
  },

  // POST request
  async post<T extends object>(url: string, data: any, contentType?: string): Promise<T> {
    try {
      const headers: Record<string, string> = {}

      // Set Content-Type based on the provided contentType parameter or default to 'application/json'
      headers['Content-Type'] = contentType || 'application/json'

      const response: AxiosResponse<T> = await instance.post(url, data, { headers })

      if ('message' in response.data && typeof response.data.message === 'string') {
        toast.success(response.data.message)
      }

      return response.data
    } catch (error: any) {
      return handleRequestError<T>(error, 'Error while posting data')
    }
  },

  // async postFile<T extends object>(url: string, data: any): Promise<T> {
  //   try {
  //     const response: AxiosResponse<T> = await instance.post(url, data, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     })
  //     if ('message' in response.data && typeof response.data.message === 'string') {
  //       toast.success(response.data.message)
  //     }
  //     return response.data
  //   } catch (error: any) {
  //     return handleRequestError<T>(error, 'Error while posting data')
  //   }
  // },

  // PUT request
  async put<T extends object>(url: string, data: any, contentType?: string): Promise<T> {
    try {
      const headers: Record<string, string> = {}
      headers['Content-Type'] = contentType || 'application/json'

      const response: AxiosResponse<T> = await instance.put(url, data)
      
      if ('message' in response.data && typeof response.data.message === 'string') {
        toast.success(response.data.message)
      }
      return response.data
    } catch (error: any) {
      return handleRequestError<T>(error, 'Error while updating data')
    }
  },

  // PATCH request
  async patch<T extends object>(url: string, data: any, contentType?: string): Promise<T> {
    try {
      const headers: Record<string, string> = {}
      headers['Content-Type'] = contentType || 'application/json'

      const response: AxiosResponse<T> = await instance.patch(url, data, { headers })

      if ('is_read' in response.data && typeof response.data.is_read === 'boolean') {
      } else if ('message' in response.data && typeof response.data.message === 'string') {
        toast.success(response.data.message)
      }

      /*  if ('message' in response.data && typeof response.data.message === 'string') {
        toast.success(response.data.message)
      } */

      return response.data
    } catch (error: any) {
      return handleRequestError<T>(error, 'Error while patching data')
    }
  },

  // DELETE request
  async delete<T extends object>(url: string): Promise<T> {
    try {
      const response: AxiosResponse<T> = await instance.delete(url)
      if (response.status === 204) {
        toast.success('Deleted successfully')
        return {} as T // Return an empty object since there's no response body
      } else if ('message' in response.data && typeof response.data.message === 'string') {
        toast.success(response.data.message)
        return response.data
      } else {
        return response.data
      }
    } catch (error: any) {
      return handleRequestError<T>(error, 'Error while deleting data')
    }
  },
  // New method for GET requests that return Blob data (e.g., PDFs)
    async getBlob(url: string, config: object = {}): Promise<Blob> {
      try {
        const response: AxiosResponse<Blob> = await instance.get(url, {
          responseType: 'blob',
          ...config,
        })
        return response.data
      } catch (error: any) {
        await handleRequestError(error, 'Error while fetching blob data')
        throw new Error('Error while fetching blob data')
      }
    },

}

export default baseService
